<template>
  <b-row>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
      <b-card 
        class="radius-card-transaction w-100 h-100" 
        body-class="bg-light-primary card-balance-transaction"
      >
        <div>
          <div class="pl-1 pt-2">
            <h5>{{$t('creator.balanceWithdraw')}} 
              <b-icon :icon="viewEye ? 'eye-fill' : 'eye-slash-fill'" class="cursor-pointer" @click="changeEye"/>
            </h5>
          </div>
          <div class="d-flex pl-1">
            <h2 class="mr-1 text-body-price">
              $ {{ viewEye ? numberFormat(total_ready,2) : 
                numberFormat(total_ready,2).toString().replace(/[0-9,.]/g, '*')}} USD
            </h2>
          </div>
        </div>  
        <div class="bg-light card-transaction-retired pl-1 p-1">
          <span>
            $ {{ viewEye ? numberFormat(total_balance, 2) +' USD '+ $t('dashboard.totalWon') : 
              numberFormat(total_balance,2).toString().replace(/[0-9,.]/g, '*') +' USD '+ $t("dashboard.totalWon")}}
          </span>
        </div>
      </b-card>
    </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
        <b-card class="overflow-hidden card-shadow radius-card-transaction h-100">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <h6 class="title-tools">{{$t('creator.activePurchases')}}</h6>
            <h2>{{ active_sales }}</h2>
            <p class="text-muted m-0">{{finished_sales}} {{$t('creator.completedPurchases')}}</p>
          </div>
        </b-card>
      </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch mb-1">
        <b-card class="overflow-hidden card-shadow radius-card-transaction h-100">
          <div class="background-left-proposal default-color-background-orders">
          </div>
          <div class="d-flex flex-column justify-content-between h-100">
            <h6 class="title-orders">{{$t('creator.currentSales')}}</h6>
            <h2>{{ new_collabs }}</h2>
            <p class="text-muted m-0">{{ active_proposals }} {{$t('creator.completedSales')}}</p>
          </div>
        </b-card>
      </b-col>
    <b-col class="col-12 col-lg-6 col-xl-3 mb-1" v-for="(card, index) in cards_works" :key="index">
      <card-work
        v-if="getPlan() === 'Free'" 
        :data="card" 
        :icon_hidden="true"
        class="h-100 d-flex align-items-stretch"
        @click_action="redirectAction"
      />

      <card-go-pro v-if="getPlan() !== 'Free'" class="d-flex align-items-stretch w-100"
        :cardSize="'card-go-transaction'"
        :textBadge="$t('creator.creatorPro')"
        :textInfo="$t('creator.textCreatorPro')"
        :img="imgNetwork('bm_influencer_pro')"
        :img_go="'img-go-transaction'"
        :position_img="'position-img-transaction'"
        :typeCard="'cardTransaction'"
        :disabledButton="true"
      />
    </b-col>

    <b-col class="col-12 mt-1">
      <b-card class="table-responsive" id="table-transactions-card">
        <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
          <div class="container-mode-views d-flex align-items-center">

            <b-tabs pills class="tabs-pills-transactions-request mb-1 mb-lg-0" v-model="tabs_requests">
              <b-tab :title="$t('creator.myTransaction')" active></b-tab>
              <b-tab :title="$t('creator.requestsStep')"></b-tab>
            </b-tabs>
            <div class="color-gray-button responsive-button border-left ml-1" v-if="checked_transactions.length !== 0">
              <span class="avenir-medium pl-1">{{$t('creator.selected_transactions')}}: {{checked_transactions.length}}</span>
            </div>
          </div>
          <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-lg-0">
            <b-button 
              :disabled="proposals_paid.length === 0"
              variant="outline-secondary"
              class="button-filters mb-1 mb-sm-0"
              @click="show_sider_filter = true"
            >
              <feather-icon icon="FilterIcon"></feather-icon>
              {{$t('creator.filterButton')}}
            </b-button>
            <b-button 
            :disabled="filters_handle_button"
              variant="outline-secondary"
              class="button-filters mb-1 mb-sm-0"
              @click="clearFilters"
            >
              <feather-icon icon="Trash2Icon"/>
              {{$t('creator.clearFilter')}}
            </b-button>
          </div>
        </b-col>         
      </b-card>
    </b-col>
    <b-col>
      <div class="scrollable-table">
        <div v-if="tabs_requests === 0">
          <b-table
            :items="format_data"
            id="table-transactions"
            :fields="$t(`creator.table_proposals.transactions`)"
            head-variant="light"
            :busy="!loader_transaction" 
            :tbody-tr-class="rowClass"
            outlined 
            show-empty

          >
            <template #head(id)>
              <b-form-checkbox v-model="selected_all"></b-form-checkbox>
            </template>
            <template #cell(id)="data">
              <div class="w-100 mb-1 position-relative" v-if="!handleDisabledRow(data.item)">
                <div class="d-flex justify-content-between position-absolute">
                  <b-form-checkbox
                    class="checkbox-table-transactions d-none position-absolute-checkbox-searcher"
                    v-model="checked_transactions"
                    :value="data.item.id.uuid"
                    :ref="`checkbox_table_${data.item.id.id}`"
                  >
                  </b-form-checkbox>
                  <h6 class="id-table-transactions" :ref="`id_table_${data.item.id.id}`">{{data.item.id.id}}</h6>
                </div>
              </div>
              <feather-icon v-else icon="AlertCircleIcon" :id="'tooltip-alert-disabled' + data.item.id.uuid" class="icon-alert-disabled"></feather-icon>
              <b-tooltip :target="'tooltip-alert-disabled' + data.item.id.uuid" triggers="hover" placement="top" v-if="handleDisabledRow(data.item)">{{ handleLabelTooltipDisabled(data.item) }}</b-tooltip>

            </template>
            <template #cell(title)="data">
              <div class="d-flex align-items-center">
                <div>
                  <span class="name-transactions">{{data.item.title}}</span>
                  <span v-if="data.item.work_type" class="text-muted m-0">
                    {{$t(`creator.type_of_work`).find((type) => type.value === data.item.work_type).text}}
                  </span>
                </div>
              </div>
            </template>
            <template #cell(type)="data">
              <b-badge pill class="type-transactions">
              {{ $t(`creator.type_transactions.${data.item.type}`) }}
              </b-badge>
            </template>
            <template
              #cell(amount)="data"
            >
                $ {{ numberFormat(data.item.amount, true) }}
            </template>
            
            <template
              #cell(status)="data"
            >
              <b-badge pill :variant="getVariantStatus(data.item)" :class="getVariantStatus(data.item)">
                {{statusText(data.item)}}
              </b-badge>
              <b-badge pill variant="badge-blue-custom" class="badge-blue-custom ml-1" v-if="data.item.status === 'program_withdrawal'">
                {{ $t('creator.type_request_status.pending')}}
              </b-badge>
            </template>
            <template
              #cell(paid_at)="data"
            >
           {{ getDatev2(new Date(data.item.paid_at)) }}
            </template>
            <template #table-busy>
              <div class="text-center my-2 h-min-empty d-flex align-items-center justify-content-center">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty>
              <no-Results
                :title= "$t('creator.messageNoResultsTransaction')"
                :show_button= "false"
                :no_border="true"
              />
            </template>
          </b-table>
        </div>
        <div v-else>
          <b-table
            :items="requests"
            id="table-transactions-requests"
            :fields="$t(`creator.table_proposals.request`)"
            head-variant="light"
            td-class="d-flex"
            :busy="!loader_transaction" 
            outlined 
            show-empty
          >
            <template #cell(title)="data">
              <div class="d-flex align-items-center">
                <div>
                  <span class="name-transactions">{{data.item.title}}</span>
                </div>
              </div>
            </template>
            <template #cell(id)="data">
              <div class="w-100 position-relative">
                <div class="d-flex justify-content-between position-absolute">
                  <h6 class="id-table-transactions" :ref="`id_table_${data.item.id}`">{{data.item.id}}</h6>
                </div>
              </div>
            </template>
            <template
              #cell(status)="data"
            >
              <b-badge pill :variant="getVariantStatusRequest(data.item.status)" :class="getVariantStatusRequest(data.item.status)">
                {{ $t(`creator.type_request_status.${data.item.status}`) }}              </b-badge>
            </template>

            <template #cell(amount)="data">
              <div class="no-wrap">
                {{ data.item.amount }}
              </div>
            </template>

            <template
              #cell(invoice)="data"
            >
              <b-button variant="flat-secondary" v-if="data.item.url_file_tax" @click="openInvoiceUrl(data.item.url_file_tax)">
                PDF
              </b-button>
              <b-button variant="flat-secondary" v-if="data.item.url_xml_tax" @click="openInvoiceUrl(data.item.url_xml_tax)">
                XML
              </b-button>
              <span v-if="(!data.item.url_file_tax && ! data.item.url_xml_tax)" class="text-center d-block">--</span>
            </template>

            <template #cell(reason_reject)="data">
              <div>
                {{ data.item.reason_reject }}
              </div>
            </template>
            
            <template #table-busy>
              <div class="text-center my-2 h-min-empty d-flex align-items-center justify-content-center">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty>
              <no-Results
                :title= "$t('creator.messageNoResultsRequests')"
                :show_button= "false"
                :no_border="true"
              />
            </template>
          </b-table>
        </div>
        <b-pagination 
          v-model="current_page"
          :total-rows="total_results" 
          :per-page="per_page"
          class="d-flex justify-content-center"
        ></b-pagination>
      </div>
    </b-col>
    <sider-filters
      class="open-sidebar-search"
      @close_siderbar_filter="closeSiderFilter"
      :show_sider_filter="show_sider_filter"
      :filters_layout="filters_layout"
      :is_transactions="tabs_requests === 0"
      @update_transaction="getFormatTable"
      @filters_requests="applyFiltersRequests"
      @update_total_results="updateTotalResults"
      v-if="show_sider_filter"
    />
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BBadge,
  BCard,
  BFormCheckbox,
  BButton,
  BSpinner,
  BPagination,
  BTabs,
  BTab,
  BTooltip
} from 'bootstrap-vue';
import { getDatev2 } from '@/libs/utils/times';
import { downloadFile } from '@/libs/utils/others';
import { getUserData } from '@/libs/utils/user';
import { getUrlCategory, getAssetUrl } from '@/libs/utils/urls';
import { numberFormat } from '@/libs/utils/formats';
import proposals_services from '@/services/proposals';
import { getTransactions, getRequests } from '@/services/finance';
import siderFilters from "@/views/pages/proposals/stepper/siderFilters.vue";
import service from "@/services/dashboard";
import cardGoPro from '@/views/components/proposals/cardGoPro.vue';


export default {
  name: 'orderProposals',
  components: {
    cardGoPro,
    BSpinner,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BCard,
    BFormCheckbox,
    BButton,
    siderFilters,
    BTabs,
    BTab,
    BTooltip,
    cardWork: () => import('@/views/pages/proposals/cardWork.vue'),
  },
  data() {
    return {
      downloadFile,
      getDatev2,
      numberFormat,
      format_data: [],
      loader_transaction: false,
      fields: [
        {
          "key": "proposal",
          "label": "Propuesta",
          "sortable": true
        },
        {
          "key": "status",
          "label": "Estatus",
          "sortable": true
        }
      ],
      cards_works: [
        {
          title: this.$t('creator.upgradeText'),
          icon: 'plusIcon',
          text: this.$t('creator.textCardWork'),
          text_action: this.$t('creator.btnText'),
          background_left: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          backgrounds: {
            container: 'linear-gradient(0deg, #A24EF0, #994EFF)',
            container_hover: '#a24ef038',
            filter_svg: 'invert(55%) sepia(95%) saturate(2200%) hue-rotate(270deg) brightness(50%) contrast(100%)',
            selected: 'linear-gradient(0deg, #A24EF0, #994EFF)',
          },
          card_action: 'redirectMembership'
        },
      ],
      proposals_paid: [],
      checked_transactions: [],
      selected_all: false,
      show_sider_filter: false,
      filters_layout: [],
      ready_balance: 0,
      viewEye: false,
      active_sales: 0,
      finished_sales: 0,
      new_collabs: 0,
      active_proposals: 0,
      current_page: 1,
      per_page: 10,
      total_results: 0,
      waiting_balance: 0,
      total_ready: 0,
      program_withdrawal_balance: 0,
      total_balance: 0,
      tabs_requests: 0,
      requests: [],
      filters_request: {},
    }
  },
  created() {
    this.getTransactionsFunction()
    this.getBalance()
    this.getPlan()
    this.getMyWorkSummary();
    this.$emit('checked_transactions', [], false)
  },
  computed: {
    filters_handle_button() {
      if (this.tabs_requests === 0) {
        return this.filters_layout.length === 0
      } else {
        return Object.keys(this.filters_request).length === 0
      }
    },
  },
  methods: {
    getRequestsData() {
      this.loader_transaction = false;
      getRequests(this.filters_request, this.per_page, this.current_page).then((response) => {
        this.formatRequests(response.response.results)
        this.total_results = response.response.count;
      });
    },
    updateTotalResults(total_results) {
      this.total_results = total_results;
    },
    applyFiltersRequests(filters) {
      this.filters_request = filters;
      this.getRequestsData()
    },
    formatRequests(requests) {
      this.requests = [];
      requests.forEach(i => {
        this.requests.push({
          id: i.sequence_number,
          title: i.transactions.length > 1 ? 'Multicampaña' : i.transactions[0].name,
          status: i.status,
          amount: `$ ${numberFormat(i.amount, 2)}`,
          reason_reject: i.rejected_reason,
          id_payment: i.payment_id,
          sent_at: getDatev2(new Date(i.created_at)),
          url_xml_tax: i.url_xml_tax,
          url_file_tax: i.url_file_tax,
        })
      })
      this.loader_transaction = true;

    },
    openInvoiceUrl(url) {
      window.open(url, '_blank')
    },
    redirectAction(method) {
      this[method]();
    },
    redirectMembership() {
      this.$router.push({name: 'pages-account-setting', params: { section: 'membership' } })
    },
    handleDisabledRow(transaction) {
      if (transaction) {
        const { ready_withdrawal, status } = transaction;
        if (status !== 'paid') { return true }
        const days = this.daysremainingforwithdrawalAction(transaction)
        return days > 0 && !ready_withdrawal
      }
    },
    handleLabelTooltipDisabled(transaction) {
      const { status, ready_withdrawal } = transaction;
      if (status === 'program_withdrawal') return this.$t('creator.prosessTransaction')
      else if (status === 'paid' && !ready_withdrawal) return this.$t('creator.dayspayout')
      return `${this.$t('creator.transactionCoulnt')} "${this.$t(`creator.states_transactions.${status}`)}"`
    },
    daysremainingforwithdrawalAction(transaction) {
      const {paid_at, days_payout} = transaction;
      const pay_day = new Date(paid_at);
      const date_withdrawal = new Date(pay_day);
      date_withdrawal.setDate(pay_day.getDate() + days_payout);

      const current_date = new Date();
      const difference_days = Math.ceil((date_withdrawal - current_date) / (1000 * 60 * 60 * 24));

      return difference_days;
    },
    statusText(transaction) {
      const { status, ready_withdrawal } = transaction;
      if (status === 'paid') {
        const days = this.daysremainingforwithdrawalAction(transaction);
        return days > 0 && !ready_withdrawal ? `${days} ${this.$t('creator.daysPayout')}` : this.$t('creator.balanceWithdraw')
      }
      if (status === 'unpaid') return this.$t('creator.noPaid')
      if (status === 'program_withdrawal') return this.$t('creator.scheduledWithdrawal')
      if (status === 'withdrawal') return this.$t('creator.mountWithdrawn')
      if (status === 'rejected') return this.$t('creator.cancelStatus')
    },
    clearFilters() {
      if (this.tabs_requests === 0) this.getTransactionsFunction()
      else {
        this.filters_request = {}
        this.getRequestsData()
      }
    },
    referenceMember() {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'membership' }})
    },
    getPlan() {
      return getUserData().current_plan.name
    },
    imgNetwork(category) {
      return getUrlCategory(category)
    },
    changeEye() {
      this.viewEye = !this.viewEye
    },
    getBalance() {
      service.getBalance().then(response => {
        this.total_balance = response.total_balance
        this.ready_balance = response.ready_balance;
        this.waiting_balance = response.waiting_balance;
        this.program_withdrawal_balance = response.program_withdrawal_balance
        this.total_ready = this.ready_balance + this.waiting_balance + this.program_withdrawal_balance;
      });
    },

    getMyWorkSummary() {
      proposals_services.myWorkSummary().then(response => {
        this.active_sales = response.active_sales;
        this.finished_sales = response.finished_sales;
        this.new_collabs = response.new_collabs;
        this.active_proposals = response.active_proposals;
      })
    },
    getTransactionsFunction() {
      this.loader_transaction = false
      this.filters_layout = []
      getTransactions(false, this.per_page, this.current_page).then(response => {
        this.checked_transactions = []
        this.proposals_paid = response.results
        this.total_results = response.count;
        this.loader_transaction = true
        this.getFormatTable(this.proposals_paid)
      })
    },
    closeSiderFilter() {
      this.show_sider_filter = false
    },
    getFormatTable(proposals, filters) {
      if (filters !== undefined) {
        this.filters_layout = filters
      }
      this.format_data = []
      proposals.map((item, index) => {
        this.format_data.push(
          {
            id: {id: index + 1, uuid: item.uuid},
            title: item.name,
            type: item.type,
            amount: item.amount,
            fee: `$ ${(parseFloat(item.amount) *  (item.service_fee / 100)).toFixed(2)}`,
            paid_at: item.paid_at ? item.paid_at : '--',
            status: item.status,
            work_type: item.metadata && item.metadata.work_type ? item.metadata.work_type : '',
            main_image: item.metadata && item.metadata.main_image ? getAssetUrl(item.metadata.main_image) : (item.casting_call ? item.casting_call.cover_url : ''),
            days_payout: item.days_payout,
            metadata: item.metadata ?? {},
            user_request: item.user_request,
            ready_withdrawal: item.ready_withdrawal

          });
      });
    },
    checkedAll() {
      this.checked_transactions = [];
      this.proposals_paid.forEach((item) => {
        if (!this.handleDisabledRow(item)) {
          this.checked_transactions.push(item.uuid)
        }
      });
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.proposals_paid.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-transactions');
            }
            
            if (idTable) {
              idTable.classList.add('d-none');
            }
          });
        }
      } else {
        for (let i = 1; i <= this.proposals_paid.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const idTable = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-transactions');
            }
            
            if (idTable) {
              idTable.classList.remove('d-none');
            }
          });
        }
      }
    },
    getVariantStatusRequest(status) {
      const status_classes = {
        rejected: 'badge-secondary-custom',
        pending: 'badge-yellow-custom',
        approved: 'badge-success-custom',
      };
      return status_classes[status] || '';
    },
    getVariantStatus(transaction) {
      const { status } = transaction;
      if (status === 'paid') {
        const days = this.daysremainingforwithdrawalAction(transaction);
        return days > 0 ? 'badge-yellow-custom' : 'badge-blue-custom'
      }
      if (status === 'rejected') return 'badge-secondary-custom'
      if (status === 'unpaid') return 'badge-yellow-custom'
      if (status === 'withdrawal' || status === 'program_withdrawal') return 'badge-success-custom'
      return 'light-secondary'
    },
    rowClass(data) {
      if (this.handleDisabledRow(data))
        return 'row-disabled-table-class-transactions'
    },
    uuidsReadyToWithdraw(checked_transactions) {
      return checked_transactions.filter(uuid => {
        const transaction = this.proposals_paid.find(tran => tran.uuid === uuid)
        return transaction?.status === 'paid' && this.daysremainingforwithdrawalAction(transaction) <= 0
      })
    }
  },
  watch: {
    checked_transactions(val) {
      this.checkCheckboxClasses(val);
      if (this.checked_transactions.length === 0) this.selected_all = false
      else if (this.checked_transactions.length === this.proposals_paid.length) this.selected_all = true
      const uuid_ready_to_withdraw = this.uuidsReadyToWithdraw(val)
      const is_ready_to_withdraw = uuid_ready_to_withdraw.length === this.checked_transactions.length && this.checked_transactions.length !== 0;
      this.$emit('checked_transactions', uuid_ready_to_withdraw, is_ready_to_withdraw)
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_transactions = [];
    },
    current_page() {
      this.selected_all = null;
      if (this.tabs_requests === 0) this.getTransactionsFunction()
      else this.getRequestsData();
    },
    tabs_requests(val) {
      this.checked_transactions = [];
      this.current_page = 1;
      this.filters_request = {}
      if (val === 1) {
        this.getRequestsData()
      } else {
        this.getTransactionsFunction();
      }
    }
  }
}
</script>
<style lang="scss">
.row-disabled-table-class-transactions{
  background-color: #b8babd29 !important;
}
.tabs-pills-transactions-request {
  .nav-pills {
    margin: 0;
  }
}
.badge-payout {
  background-color: #FCD147;
  color: black;
}
.position-img-transaction {
  top: -5.2em;
}
.img-go-transaction {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-bottom: 1em;
  border-radius: 100%;
}
.card-go-transaction {
  border-radius: 1em;
  display: flex;
  overflow: hidden;
  width: 100%;
}
.card-transaction-retired {
  display: flex;
  align-items: center;
  border-radius: 0 0 1em 1em;
  color: #000000;
}
.radius-card-transaction {
  border-radius: 1em !important;
}
.name-transactions {
  color: #55a6c4 !important;
  font-weight: bold !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.status-transactions {
  background-color: #212529 !important;
}
.type-transactions {
  background-color: rgba(19,33,68,.1) !important;
  color: #495057 !important;
  font-weight: bold !important;
}
#table-transactions-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-transactions-requests td {
  background-color: white;
}
#table-transactions tr {
  background-color: white;
}
#table-transactions tr:hover {
  background-color: #EFF3F6;
  cursor: pointer;
  transition: all 100ms;
}
.thead-light th {
  background-color: white !important;
}
.checkbox-table-transactions {
  display: none !important;
}
#table-transactions tr:hover .checkbox-table-transactions {
  display: block !important;
}
#table-transactions tr:hover .id-table-transactions {
  display: none;
}
.button-filters {
  margin-left: 5px;
}
.button-filters:focus {
  margin-left: 5px;
  background-color: transparent !important;
}
.open-sidebar-search > .b-sidebar-right {
animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
.card-balance-transaction {
  padding: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 1em !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
</style>
<style scoped lang="scss">
.icon-alert-disabled {
  color: #8d8d8d; 
} 
.h-min-empty {
  height: 400px;
}
.card-shadow {
  width: 100%;
}
.badge-blue-custom {
  border: 1px solid #93e3fa;
  background-color: #bef3fc;
  color: #0050c5;
}
.badge-success-custom {
  border: 1px solid #95ee6e;
  background-color: #c6f9ae;
  color: #0b6515;
}
.badge-secondary-custom {
  border: 1px solid #d2d9e0;
  background-color: #e8ebef;
  color: #4e5665;
}
.badge-yellow-custom {
  background-color: #ffd1a4;
  color: #f24e20;
}
.no-wrap {
  white-space: nowrap;
}
</style>