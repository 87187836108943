<template>
  <b-row>
    <b-col>
      <div class="d-flex justify-content-between flex-wrap ml-1 my-1 my-xl-0">
        <h2 class="col-12 p-0 col-sm text-center text-sm-left">{{ $t('dashboard.collaborations') }}</h2>
        <b-button v-if="active_tab === 1"
          class="blue-button-proposals col-12 col-sm-auto"  
          variant="blue-button-proposals"
          @click="redirectOption()"
        >{{ $t("dashboard.createProposal") }}</b-button>

        <b-button v-if="active_tab === 4"
            class="blue-button col-12 col-sm-auto"  
            variant="blue-button"
            @click="collectMoney()"
            :disabled="!loaded_data_user"
          >{{ $t("dashboard.collectMoney")}} 

          <span v-if="transactions_ready_withdraw.length > 0">({{ transactions_ready_withdraw.length }})</span>
        </b-button>

        <div v-if="active_tab === 5" class="p-0 col-12 col-sm-auto">
          <b-button v-if="!ready_profile"
            class="blue-button-proposals px-0 col-12 col-sm"  
            variant="blue-button-proposals"
            @click="$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})"
          >{{ $t("dashboard.createProfile")}}</b-button>

          <b-button v-else
            class="blue-button col-12 col-sm"  
            variant="blue-button"
            @click="redirectProfilePublic()"
          >{{ $t("creator.goPublicProfile")}}</b-button>
        </div> 
      </div>
      <b-tabs content-class="mt-3" v-model="active_tab">
        <b-tab :title="$t('creator.desk')" title-link-class="custom-tab" @click="$router.push({name: 'dashboard-analytics'})"></b-tab>
        <b-tab :title="$t('creator.myProposals')" title-link-class="custom-tab" :active="$route.params.section === 'proposals'"
          @click="$router.push({ name: 'my_work', params: { section: 'proposals' } }).catch(() => {})">
          <my-proposals/>
        </b-tab>
        <b-tab :title="$t('creator.myPitches')" title-link-class="custom-tab" :active="$route.params.section === 'pitches'"
          @click="$router.push({ name: 'my_work', params: { section: 'pitches' } }).catch(() => {})">
          <pitches />
        </b-tab>

        <b-tab :title="$t('creator.myOrders')" title-link-class="custom-tab" :active="$route.params.section === 'orders'"
          @click="$router.push({ name: 'my_work', params: { section: 'orders' } }).catch(() => {})">
          <order-proposals v-if="active_tab === 3"/>
        </b-tab>

        <b-tab :title="$t('creator.myTransaction')" title-link-class="custom-tab" :active="$route.params.section === 'transactions'"
          @click="$router.push({ name: 'my_work', params: { section: 'transactions' } }).catch(() => {})">
          <transactions-proposals v-if="active_tab === 4" @checked_transactions="transactionSelected" ref="transactions_proposals_component"/>
        </b-tab>

        <b-tab :title="$t('creator.myNetworks')" title-link-class="custom-tab" :active="$route.params.section === 'networks'"
          @click="$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})">
          <profile-networks v-if="active_tab === 5"></profile-networks>
        </b-tab>
      </b-tabs>
    </b-col>

    <modal-withdraw-money 
      :show_modal="show_modal_withdraw" 
      :key="update_modal_withdraw" 
      :transactions_ready_withdraw="transactions_ready_withdraw"
      @update_transaction="updateTransaction"
    ></modal-withdraw-money>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BButton,
} from 'bootstrap-vue';
import myProposals from '@/views/pages/proposals/myProposals.vue';
import profileNetworks from '@/views/pages/proposals/profileNetworks.vue';
import orderProposals from '../proposals/orders.vue'
import transactionsProposals from '../proposals/transactions.vue'
import pitches from '@/views/pages/casting-calls/pitches.vue';
import service_others from '@/services/others';
import ResendEmail from '@/views/components/ResendEmail.vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getUserData } from '@/libs/utils/user';


export default {
  name: 'myWork',
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
    myProposals,
    profileNetworks,
    orderProposals,
    transactionsProposals,
    pitches,
    ModalWithdrawMoney: () => import('@/views/components/modal/ModalWithdrawMoney')
  },
  data() {
    return {
      create_proposal: getUserData().meta_info,
      active_tab: 1,
      groupRol: 'creator',
      ready_profile: false,
      transactions_ready_withdraw: [],
      data_user: {},
      loaded_data_user: false,
      show_modal_withdraw: false,
      update_modal_withdraw: false,
      is_ready_withdraw: false,
    }
  },
  beforeCreate () {
    service_others.getProfile()
      .then(response => {
        this.data_user = response.user;
        this.loaded_data_user = true;
        this.groupRol = this.data_user.main_group.name;
        if (this.groupRol !== 'creator') this.$router.push({name: 'auth-login',})
        this.ready_profile = this.data_user.meta_info && !!this.data_user.meta_info.vanity_name
        this.meta_info = this.data_user.meta_info 
        this.email = this.data_user.email
        this.is_verified = this.data_user.is_verified
      })
      .catch(() => {
        this.loaded_data_user = true;
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('messageAccountSettingError'),
              icon: 'CoffeeIcon',
              variant: 'danger',
              text:this.$t('messageAccountSettingFailed')
            }
          })
        })
      })
  },
  methods: {
    redirecPayment() {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'payment-data' } })
    },
    updateTransaction() {
      this.$refs.transactions_proposals_component.getTransactionsFunction()
      this.transactions_ready_withdraw = [];
      this.show_modal_withdraw = false;
      this.update_modal_withdraw = !this.update_modal_withdraw;
    },
    hasAnyPaymentData() {
      return !!(this.data_user.paypal_info?.email_payoneer || this.data_user.paypal_info?.email_paypal || this.data_user.payment_data);
    },
    transactionSelected(data, is_ready_withdraw) {
      this.is_ready_withdraw = is_ready_withdraw;
      this.transactions_ready_withdraw = data;
    },
    redirectOption() {
      if (this.create_proposal) {
        this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
      } else {
        this.$router.push({ name: 'my_work', params: { section: 'networks' } }).catch(() => {})
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: this.$t('creator.createProfilePublic'),
              text: this.$t('creator.msjCreateProfilePublicProposals'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
    collectMoney() {
      if (this.data_user && !this.is_verified) {
        this.$toast.error({
          component: ResendEmail,
          position:"top-center",
          props:{
            userData: {
              email: this.email
            },
            is_verified: this.is_verified,
          }
        })
      } else if (!this.hasAnyPaymentData()) {
        this.$toast.error({
          component: ResendEmail,
          position:"top-center",
          props:{
            userData: {
              email: this.email,
            },
            payment_data: true,
            is_verified: this.is_verified,
          }
        })
      } else if (this.transactions_ready_withdraw.length === 0) {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            listeners: {
              clickLink: this.redirecPayment
            },
            props: {
              title: this.$t('creator.attention'), 
              text: this.$t('creator.selectTransactionAtLeast'), 
              icon: 'AlertTriangleIcon', 
              variant: 'warning',
              textLink: this.$t('creator.isComplete')
            }
          })
        })
      } else if (!this.is_ready_withdraw) {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            listeners: {
              clickLink: this.redirecPayment
            },
            props: {
              title: this.$t('creator.transactionsReady'), 
              text: this.$t('creator.transactionsReadyText'), 
              icon: 'AlertTriangleIcon', 
              variant: 'warning',
              textLink: this.$t('creator.isComplete')
            }
          })
        })
      } else {
        this.show_modal_withdraw = true;
        this.update_modal_withdraw = !this.update_modal_withdraw;
      }
    },
    redirectProfilePublic() {
      const data_user = JSON.parse(localStorage.getItem('userData'));
      const vanity_name = data_user.meta_info.vanity_name
      window.open(`/@${vanity_name}`, '_blank')
    },
  },
}
</script>
<style scoped>
.blue-button-proposals {
  background-color: #0096fe !important;
  border: 0 !important;
  color: white !important;
  width: 353px;
} 
.blue-button-proposals:hover {
  background-color:rgb(77, 182, 249) !important;
  box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  color: white !important;
  width: 353px;
}
.blue-button-proposals:focus {
  background-color: #0096fe !important;
  color: white !important;
  width: 353px;
}
.button-proposal {
  align-items: center;
  background-color: #55a6c4 !important;
  border:none;
}
.button-proposal:focus {
  background-color: #55a6c4 !important;
}
.alert-pop {
  background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
  cursor: pointer;
}
.title-pop {
  color: white;
  font-weight: bold;
}
.subtitle-pop {
  color: white;
}
</style>
<style>
.custom-tab:not(.active) {
  color: #8d8d9d8f ;
}
</style>