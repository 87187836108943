import axios from './axios_interceptors'
import config from '@/services/config'
import { tokenExpired } from '@/auth/utils'

export const getTransactions = (type_transaction = false, size_page = 10, page = 1) => {
  const param = type_transaction ? `?type_transaction=${type_transaction}&order_by=-paid_at` : '?order_by=-paid_at';
  return axios.get(`${config.urlCore}/api/v3/finance/transactions/${param}&size_page=${size_page}&page=${page}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}
export const requestBalance = (data) => {
  return axios.post(`${config.urlCore}/api/v3/finance/request-balance/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}
export const getRequests = (data, size_page = 2, page = 1) => {
  return axios.post(`${config.urlCore}/api/v3/finance/get-requests/?size_page=${size_page}&page=${page}`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}